import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { StoreState } from '@store/store.state';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';

const url: string = environment.url;
const url2: string = environment.url2;
const api: string = environment.api;
const newsURL: string = environment.news;
const headers: any = environment.gs.headers;
const sms: any = environment.micro;

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private http: HttpClient,
    private store: Store<StoreState>,
  ) { }

  getMaster<T> (query: string): Observable<T> {
    return this.http.get<T>(url + query, { headers });
  }

  getPainelMaster<T> (query: string): Observable<T> {
    return this.http.get<T>(api + query);
  }

  getOfertasMaster<T> (query: string): Observable<T> {
    return this.http.get<T>(`${environment.mkt.url}/${query}`, { headers });
  }

  postMaster<T> (query: string, data: any): Observable<T> {
    return this.http.post<T>(url + query, data, { headers });
  }

  postMaster2<T> (query: string, data: any, headers: any): Observable<T> {
    return this.http.post<T>(url + query, data, { headers });
  }

  putMaster2<T> (query: string, data: any, headers: any): Observable<T> {
    return this.http.post<T>(query, data, { headers });
  }

  getNewsMaster = (query: string): Observable<any> =>
    this.http.get<any>(`${newsURL}/${query}`);

  getFake = (): Observable<Object> => this.http.get<Object>('./assets/db/lp.json');

  // ##############
  // TODO: AUTTH
  // ##############
  getAuth(query: string, token: string): Observable<any>  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
    return this.http.get<any>(`${url}/${query}`, { headers });
  }

  get2Auth(query: string, token: string): Observable<any>  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
    return this.http.get<any>(`${url2}/${query}`, { headers });
  }

  getDDV(token: string): Observable<any>  {
    const url_ddv = 'https://kong.cndr.me/int/transations/balance';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
    return this.http.get<any>(url_ddv, { headers });
  }

  getDDVSaldo(token: string): Observable<any>  {
    const url_ddv = 'https://kong.cndr.me/int/transations/balance/ddv';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
    return this.http.get<any>(url_ddv, { headers });
  }

  postAuth(query: string, data: any, token: string): Observable<any>  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
    return this.http.post<any>(`${url}${query}`, data, { headers });
  }

  // LOGOS
  getLogos() {
    return this.http.get('https://kong.cndr.me/int/fornecedores');
  }

  sendSMS<T> (data: any): Observable<T> {
    return this.http.post<T>(`${sms}/send-sms`, data);
  }

  sendSAC<T> (data: any): Observable<T> {
    return this.http.post<T>(`${sms}/send-sac`, data);
  }

  sendEmailForgotPassword<T>(data: any): Observable<T> {
    return this.http.post<T>(`${sms}/email-precadastro`, data)
  }

  upgrade(data: any): Observable<any> {
    return this.http.post<any>(`${url}/mobile/user/update1`, data);
  }

  postUpdateUser(data: any, hash: string): Observable<any> {
    const headers = {
      Authorization: `${hash}`
    };
    return this.http.post<any>(`${url}/mobile/user/update`, data, { headers })
  }

  getBannerNds() {
    return this.http.get('https://clube.cndr.me/api/numero-da-sortes?populate=Banner,Regulamento')
  }

  getCEP(cep: string): Observable<Object> {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json`);
  }

  getStoreData<T>(reducer: any, output: any): Observable<T> {
    return this.store.select(reducer).pipe(
      filter((row: any) => !row.loading),
      map((res: any) => res[output])
    );
  }

  getNDS(token: string): Observable<any>  {
    const url = 'https://kong.cndr.me/int/nds/cliente';
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  getMarcas(fornecedor: number): Observable<any>  {
    const url = 'https://kong.cndr.me/int/fornecedores/code/' + fornecedor;
    console.log(url);
    return this.http.get<any>(url);
  }
}