import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import * as Sentry from "@sentry/angular-ivy";
import { ConfigStoreModule } from '@store/config-store.module';
import { ConfigCoreModule } from '@core/modules/config-core.module';
import { ConfigCordovaModule } from '@core/modules/config-cordova.module';
import { MenuPainelModule } from '@core/widgets/menu-painel/menu-painel.module';
import { InitService } from '@core/services/init.service';
import { AppRoutes } from 'src/app/app.routes';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutes,
    CommonModule,
    MenuPainelModule,
    ConfigCoreModule,
    ConfigStoreModule,
    ConfigCordovaModule,
    IonicModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    InitService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: 'googleTagManagerId', useValue: 'GTM-NN8MXDH' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
